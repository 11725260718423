/* Innocent */
@font-face {
  font-family: 'innocent-book';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/book.otf') format('opentype');
}
@font-face {
  font-family: 'innocent-condensed_book';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/condensed_book.otf') format('opentype');
}
@font-face {
  font-family: 'innocent-condensed_medium';
  font-style: normal;
  font-weight: 500;
  src: url('/fonts/condensed_medium.otf') format('opentype');
}
@font-face {
  font-family: 'innocent-headline_bold';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/headline_bold.otf') format('opentype');
}
@font-face {
  font-family: 'innocent-headline_thin';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/headline_thin.otf') format('opentype');
}
@font-face {
  font-family: 'innocent-super_scrawl';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/super_scrawl.ttf') format('truetype');
}
