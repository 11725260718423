:root {
  --highlight-width: auto;
  --highlight-x-pos: 0;
}

html {
  scroll-behavior: smooth !important;
}

#impacts {
  scroll-margin-top: 77px;
}

p {
  color: #2A2A2A
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}
 
/* temporary solution to customize carousel dots color */
.slick-dots button:before {
  opacity: .5 !important;
  color: white !important;
}

 .slick-dots li.slick-active button:before {
  opacity: 1 !important;
  color: white !important;
}

.slick-dots li button:before {
  font-size: 8px !important;
}
.slick-dots li {
  width: 10px !important;
  height: 10px !important;
}

@keyframes skeletonBG {
  from {background-color: #F6F5F1;}
  to {background-color: white;}
}

.projectImage {
  transition: transform 0.5s ease-in-out;
}

.projectImage:hover {
  transform: scale(1.2);
}

.z-index--1 {
  z-index: -1;
}

/* hide glider slider ugly scrollbars */
.glider {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.glider::-webkit-scrollbar {
  display: none;
}


/* pagination */

.miiPagination .rc-pagination-item {
  height: 40px;
  width: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-family: "AcidGrotesk", Sans-Serif;
  font-weight: bold;
  border: none;
  color: #003F2C;
  border-radius: 0;
  text-decoration: none;
  transition: all 0.1s;
  border-color: #003F2C
}

.miiPagination .rc-pagination-item a{
  color: #003F2C;
}

.miiPagination .rc-pagination-item-active {
  border: none;
  border-bottom: 2px solid #003F2C;
}

.miiPagination .rc-pagination-item-active:focus a {
  color: #003F2C;
}

.miiPagination .rc-pagination-item-active:focus, .rc-pagination-item-active:hover {
  border-color: #003F2C
}

.miiPagination .rc-pagination-item:hover {
  border: none;
  border-bottom: 2px solid #003F2C;
}

.miiPagination .rc-pagination-item:hover a {
  color: #003F2C;
}

.miiPagination .rc-pagination-prev .rc-pagination-item-link {
  border: none
}

.miiPagination .rc-pagination-next .rc-pagination-item-link {
  border: none
}

.miiPagination .rc-pagination-disabled .rc-pagination-item-link {
  color: #003F2C;
  border: none
}

.exitTextVideo {
	/* -webkit-animation: exitTextVideo 1.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) 8s forwards;
	        animation: exitTextVideo 1.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) 8s forwards;
 */
animation-name: fadeInFwd, exitTextVideo;
animation-duration: 2.2s, 2s;
animation-delay: 4.5s, 6.7s;
animation-timing-function: cubic-bezier(0.390, 0.575, 0.565, 1.000), cubic-bezier(0.250, 0.460, 0.450, 0.940);
animation-iteration-count: 1, 1;
}


 @-webkit-keyframes fadeInFwd {
  0% {
    -webkit-transform: translateZ(-80px);
            transform: translateZ(-80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes fadeInFwd {
  0% {
    -webkit-transform: translateZ(-80px);
            transform: translateZ(-80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}

 @-webkit-keyframes exitTextVideo {
  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(80px);
            transform: translateZ(80px);
    opacity: 0;
  }
}
@keyframes exitTextVideo {
  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(80px);
            transform: translateZ(80px);
    opacity: 0;
  }
}

.fadeInTop {
	-webkit-animation: fade-in-top 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in-top 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

.line-clamp2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}


.floating { 
  animation-name: float;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.floatingTwo  {
  animation-name: float;
  animation-duration: 4.2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-delay: 1.6s;
}


@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-28px);
	}
	100% {
		transform: translatey(0px);
	}
}
